<template>
  <UICard>
    <h1>Cards IO</h1>
    <h2>{{ matchID }}</h2>
    <Button class="el" id="copycode" v-on:click="CopyCode">Copy Code</Button>
    <Button class="el" v-on:click="ExitLobby">Exit</Button>
    <h4>Players: {{ joined }} / 2</h4>
    <h6>The game will start automatically when there are enough players</h6>
  </UICard>
</template>

<script>
import LCM from '@/Game/client'
import UICard from '@/components/UICard'
import Button from '@/components/Button'

export default {
  name: "Lobby",
  components: {Button, UICard},
  props: {
    matchID: String
  },
  data() {
    return {
      joined: 1,
      updateID: 0,
      playerID: '',
      auth: '',
      leaveOnUnmount: true
    }
  },
  async created() {
    const {playerID, auth} = await LCM.joinGame(this.matchID)
    this.playerID = playerID
    this.auth = auth
    this.updateID = setInterval(this.updateJoined, 1000)
  },
  unmounted() {
    clearInterval(this.updateID)
    if (this.leaveOnUnmount) {
      LCM.leaveGame(this.matchID, this.playerID, this.auth)
      console.log("Leaving")
    }
  },
  methods: {
    async updateJoined() {
      this.joined = await LCM.getJoinedNum(this.matchID)
      if (this.joined === 2) {
        this.StartGame()
      }
    },
    ExitLobby() {
      this.$router.back()
    },
    StartGame() {
        this.leaveOnUnmount = false
        this.$router.replace({
          name: 'Game',
          params: {
            matchID: this.matchID,
            playerID: this.playerID,
            auth: this.auth
          }
        })
    },
    CopyCode() {
      const tempInput = document.createElement('input')
      tempInput.value = this.matchID
      document.body.appendChild(tempInput)
      tempInput.select()
      document.execCommand('copy')
      document.body.removeChild(tempInput)
    }
  }
}
</script>

<style scoped>
#copycode:active {
  color: white;
  background-color: black;
}
</style>
